
import { BDropdown, BDropdownItem, BSpinner } from "bootstrap-vue";
import { AvButton } from "@/components";
import Ripple from "vue-ripple-directive";
import { Component, Prop, Vue } from "vue-property-decorator";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as XLSX from "xlsx";
import { stringify as csvStringify } from "csv-stringify";

@Component({
  name: "AvExportButton",
  components: {
    AvButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  directives: {
    Ripple,
  },
})
export default class AvExportButton extends Vue {
  @Prop({ default: "report" }) fileName!: string;
  @Prop({ default: ["excel", "csv"] }) exportTypes!: ["excel", "csv"];
  @Prop({ required: true, default: async () => [] }) getData!: () => Promise<
    object[]
  >;

  loading = false;

  generateFile(type: "excel" | "csv") {
    this.loading = true;
    this.getData()
      .then((response: object[]) => {
        if (type == "excel") {
          this.generateExcel(response, this.fileName);
        }

        if (type == "csv") {
          this.generateCSV(response, this.fileName);
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Arquivo gerado com sucesso!",
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao gerar arquivo",
            text: "Ocorreu um erro ao tentar gerar seu arquivo para download!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  generateExcel(data: object[], fileName: string): void {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  generateCSV(data: object[], fileName: string): void {
    const csvData: any[] = [];

    if (data.length > 0) {
      const keys = Object.keys(data[0]);

      // Adicionar a linha de cabeçalho ao array CSV
      csvData.push(keys);
    }

    // Transformar o array de objetos em um array de arrays de valores
    data.forEach((obj) => {
      const row: any[] = [];
      Object.values(obj).forEach((value) => {
        row.push(value);
      });
      csvData.push(row);
    });

    // Converter o array de arrays em uma string CSV
    csvStringify(csvData, {
      delimiter: ';',
      bom: true
    }, (err: any, output: any) => {
      if (err) {
        console.error(err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao gerar arquivo",
            text: "Ocorreu um erro ao tentar gerar seu arquivo para download!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return;
      }

      // Criar um elemento de link para fazer o download do arquivo CSV
      const link = document.createElement("a");
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(output)}`;
      link.download = `${fileName}.csv`;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
