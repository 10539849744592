
import { BFormCheckbox } from "bootstrap-vue";
import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component( {
  name: "AvSwitch",
  components: {
    BFormCheckbox,
  }
} )
export default class AvSwitch extends Vue {
  @Prop() value!: any;
  @Prop() inputValue!: any;
  @Prop() label!: string;
  @Prop( { default: false } ) disabled!: boolean;
  @Prop() size!: string;
  @Prop() labelClass!: string;

  // Data
  localChecked: boolean = false;

  // Computeds
  get checked() {
    if( Array.isArray( this.value ) ) {
      return this.value.includes( this.inputValue );
    }

    return this.value;
  }

  // Watchs
  @Watch( "value", { immediate: true } )
  handlerValue() {
    if( Array.isArray( this.value ) ) {
      this.localChecked = this.value.includes( this.inputValue );
    } else if( this.value ) {
      this.localChecked = true;
    } else {
      this.localChecked = false;
    }
  }

  // Methods
  handleChange( args: any ) {
    if( Array.isArray( this.value ) ) {
      if( args ) {
        if( this.value.indexOf( this.inputValue ) === -1 ) this.$emit( "input", [ ...this.value, this.inputValue ] );
      } else {
        let localValue = JSON.parse( JSON.stringify( this.value ) );
        localValue.splice( localValue.indexOf( this.inputValue ), 1 );

        this.$emit( "input", localValue );
      }
    } else {
      this.$emit( "input", args );
    }
  }
};
