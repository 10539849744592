
import { BButton, BSpinner } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component( {
  name: "AvButton",
  components: {
    BButton,
    BSpinner
  },
  directives: {
    Ripple,
  }
} )
export default class AvButton extends Vue {
  @Prop( { default: "primary" } ) variant!: string;
  @Prop( { default: false } ) disabled!: boolean | string;
  @Prop( { default: "" } ) size!: string;
  @Prop( { default: "" } ) type!: string;
  @Prop( { default: false } ) loading!: boolean | string;
  
  public get getDisabled() : boolean | string {
    if( typeof this.loading == "string" || this.loading ) {
      return this.loading;
    } else if( typeof this.disabled == "string" || this.disabled ) {
      return this.disabled;
    } else {
      return false;
    }
  }
  

  emitClick( args: any ) {
    if( !this.disabled && !this.loading ) {
      this.$emit( "click", args );
    }
  }
}
